import { buildHtml } from '@amazeelabs/react-framework-bridge/gatsby';

export default function reshapeAccordionData(items: Array<{}> = []) {
  let newAccordionItems: {
    title: string | undefined;
    content: string | undefined;
  }[] = [];

  items.forEach((item, index) => {
    let thisItem = {};
    for (const [key, value] of Object.entries(item)) {
      if (key === 'accordion_content') {
        thisItem[key] = buildHtml(value);
      } else {
        thisItem[key] = value;
      }
    }
    newAccordionItems.push(thisItem);
  });

  return newAccordionItems;
}
